import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "../components/error/FallbackComponent";
import RootLayout from "../layout/RootLayout";
import Login from "../pages/Login";
import RouterError from "../components/error/RouterError";
import RecruitersPage from "../pages/RecruitersPage";
import { ProtectedRoute } from "./ProtectRoutes";
import DashboardLayout from "../layout/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import JobsPage from "../pages/JobsPage";
import CandidatePage from "../pages/CandidatePage";
import ClientsPage from "../pages/ClientsPage";
import CreditUsagePage from "../pages/CreditUsagePage";
import ForgotPassword from "../pages/ForgotPassword";
import SearchCandidatePage from "../pages/SearchCandidatePage";
import CandidateForm from "../pages/CandidateForm";
import ForgotPasswordOTP from "../pages/ForgotPasswordOTP";
import BlacklistCandidates from "../pages/BlacklistCandidates";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <RootLayout />
      </ErrorBoundary>
    ),
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Navigate to="/login" replace />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "forgotpassword-otp",
        element: <ForgotPasswordOTP />,
      },
      {
        path: "s-update/:id/:token",
        element: <CandidateForm />,
      },
      {
        path: "*",
        element: <ProtectedRoute />,
        children: [
          {
            path: "recruiters",
            element: (
              <DashboardLayout>
                <RecruitersPage />
              </DashboardLayout>
            ),
          },
          {
            path: "dashboard",
            element: (
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            ),
          },
          {
            path: "jobs",
            element: (
              <DashboardLayout>
                <JobsPage />
              </DashboardLayout>
            ),
          },
          {
            path: "candidates",
            element: (
              <DashboardLayout>
                <CandidatePage />
              </DashboardLayout>
            ),
          },
          {
            path: "create-candidate",
            element: (
              <DashboardLayout>
                <CandidateForm />
              </DashboardLayout>
            ),
          },
          {
            path: "update-candidate/:id",
            element: (
              <DashboardLayout>
                <CandidateForm />
              </DashboardLayout>
            ),
          },
          {
            path: "blacklist-candidates",
            element: (
              <DashboardLayout>
                <BlacklistCandidates />
              </DashboardLayout>
            ),
          },
          {
            path: "search-candidates",
            element: (
              <DashboardLayout>
                <SearchCandidatePage />
              </DashboardLayout>
            ),
          },
          {
            path: "clients",
            element: (
              <DashboardLayout>
                <ClientsPage />
              </DashboardLayout>
            ),
          },
          {
            path: "credits-usage",
            element: (
              <DashboardLayout>
                <CreditUsagePage />
              </DashboardLayout>
            ),
          },
        ],
      },
    ],
  },
]);
function RouteProvider() {
  return <RouterProvider router={router} />;
}

export default RouteProvider;
