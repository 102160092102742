import React, { useEffect, useState } from "react";
import "../styles/Login.css";
import {
  chakkraLogo,
  loginBackground,
  loginSlide1,
  loginSlide2,
  loginSlide3,
} from "../assets";
import LoginSlide from "../components/login/LoginSlide";
import { post } from "../lib/Api";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";

const ForgotPassword = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const slides = [
    {
      image: loginSlide1,
      heading: "Secure Login",
      description: "Login confidently with our advanced encryption security.",
      loadingDivCount: 3,
      activeCircleIndex: 1,
    },
    {
      image: loginSlide2,
      heading: "Unlock Chakrra with a Tap",
      description:
        "Enjoy seamless login for Chakrra: Password-Free, Simplified Security",
      loadingDivCount: 3,
      activeCircleIndex: 2,
    },
    {
      image: loginSlide3,
      heading: "Seamless Access, Securely",
      description: "Simplify access without risking your security.",
      loadingDivCount: 3,
      activeCircleIndex: 3,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const backgroundImageStyle = {
    backgroundImage: `url(${loginBackground})`,
  };

  const onLogin = async () => {
    const data = { username: email };
    setLoading(true);
    try {
      const res = await post(`user/forgot-password`, data, {}, false);
      if (res?.status === true) {
        navigate("/forgotpassword-otp", { state: { data } });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({ message: "Network Error", type: "error" });
      console.log("Error: ", error);
    }
  };

  return (
    <div className="background-image" style={backgroundImageStyle}>
      <div className="container">
        <div className="left-panel">
          <div>
            <img src={chakkraLogo} alt="Company Logo" className="logo" />
          </div>
          <div className="heading">Forgot Password</div>
          <div className="input-group">
            <label>Email</label>
            <div className="input-with-icon">
              <input
                type="text"
                className="input"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
          </div>
          <div>
            <button className="button" onClick={onLogin} disabled={loading}>
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>
          </div>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <div className="forgot-password">
              <span>Signin?</span>
            </div>
          </Link>
          {/* <div className="contact-admin">
            Having issues with your login? <span>Contact Admin</span>
          </div> */}
        </div>
        <LoginSlide {...slides[currentSlide]} />

        <ToastContainer />
      </div>
    </div>
  );
};

export default ForgotPassword;
